@import "../../mixins";

.navbar {
    width: 100%;
    //height: 60px;
    //background-color: #fdc70c;
    color: black;
    //padding: 0 10rem;
    @include d-flex(row, space-between, center);

    .brand {
        color: #fff;
    }

    .hamburger {
        display: none;
    }

    .menu {
        flex: 1;
        //margin-left: 2rem;

        ul {
            @include d-flex(row, flex-start, center);
            gap: 2rem;
            text-align: right;
            padding-top: 20px;
            padding-bottom: 20px;

            .close {
                display: none;
            }

            li {
                a {
                    color: black;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .navbar {
        .hamburger {
            display: block;
            margin-left: 1rem;
            cursor: pointer;
        }

        .menu {
            width: 100vw;
            height: 100vh;
            margin: 0;
            padding: 0;
            background-color: rgb(255, 244, 155);
            position: fixed;
            left: -100vw;
            top: 0;
            z-index: 3;
            transition: all 0.3s linear;

            &.open {
                left: 0;
            }

            ul {
                @include d-flex(column, center, center);
                height: 100%;
                gap: 3rem;
                position: relative;

                .close {
                    display: block;
                    position: absolute;
                    top: 1rem;
                    right: 2rem;
                    cursor: pointer;
                    font-size: 30px;
                }
            }
        }
    }
}