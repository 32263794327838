*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Archivo, sans-serif;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

.wrapper {
    padding: 1rem;
}

@mixin flUI-span($span, $start: auto, $row: auto) {
    grid-column: $start / span $span;
    grid-row: $row;
    /* media query for small devices */
    @media screen and (max-width: 480px) {
        grid-column: 1 / span 12;
        grid-row: auto;
    }
}

@function px-to-rem($pixel) {
    @return ($pixel / 16) * 1rem;
}

%gridContainer {
    box-sizing: border-box;
    /*padding: 1em;*/
    display: inline-block;
    justify-content: center;
}

.fl-grid {
    margin-left: 0px;
    margin-right: 0px;
}

.fl-grid {
    font-family: Archivo, sans-serif;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin: auto px-to-rem(8);
    /* > 1366 pixel */
    column-gap: px-to-rem(32);
    row-gap: px-to-rem(32);
    /* < 1366 pixel */
    @media screen and (max-width: 1366px) {
        column-gap: px-to-rem(24);
        row-gap: px-to-rem(24);
    }
    /* <= 640 pixel */
    @media screen and (max-width: 640px) {
        column-gap: px-to-rem(16);
        row-gap: px-to-rem(16);
    }
}

.fl-span1 {
    @extend %gridContainer;
    @include flUI-span(1);
}

.fl-span2 {
    @extend %gridContainer;
    @include flUI-span(2);
}

.fl-span3 {
    @extend %gridContainer;
    @include flUI-span(3);
}

.fl-span4 {
    @extend %gridContainer;
    @include flUI-span(4);
}

.fl-span5 {
    @extend %gridContainer;
    @include flUI-span(5);
}

.fl-span6 {
    @extend %gridContainer;
    @include flUI-span(6);
}

.fl-span7 {
    @extend %gridContainer;
    @include flUI-span(7);
}

.fl-span8 {
    @extend %gridContainer;
    @include flUI-span(8);
}

.fl-span9 {
    @extend %gridContainer;
    @include flUI-span(9);
}

.fl-span10 {
    @extend %gridContainer;
    @include flUI-span(10);
}

.fl-span11 {
    @extend %gridContainer;
    @include flUI-span(11);
}

.fl-span12 {
    @extend %gridContainer;
    @include flUI-span(12);
}

.fl-span6c {
    @extend %gridContainer;
    @include flUI-span(6, 4, 3);
}

.footer div div {
    padding-bottom: 10px;
}