@import "../../mixins";

.home {
    @include main-style();

    h1 {
        color: #0000ff;
    }

    img {
        width: 350px;
        max-width: 95%;
        height: auto;
    }
}

ul.list li {
    margin-bottom: 15px;
    font-size: large;
}

@media (min-width: 641px) {
    .home-text {
        padding-left: 40px;
        padding-top: 220px;
    }

    .home-text .title {
        font-size: xx-large;
        padding-bottom: 20px
    }

    .home-text .text {
        font-size: large;
    }
}

@media (max-width: 640px) {
    .home-text {
        padding-left: 0px;
        padding-top: 0px;
    }

    .home-text .title {
        font-size: medium;
    }

    .home-text .text {
        font-size: unset;
    }
}