html {
    height: 100vh;
    font-family: Archivo, sans-serif;
}

body {
    height: 99vh;
    font-family: Archivo, sans-serif;
}

.primary-color {
    color: #fde354;
}

.primary-background-color {
    background-color: #fde354;
}

.light-primary-background-color {
    background-color: rgb(255, 244, 155);
}

.container {
    height: 99vh;
}

#main-container {
    min-height: 99vh;
}

#main-content {
    min-height: 94vh;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* width */
::-webkit-scrollbar:horizontal {
    height: 0px;
}

/* Track */
::-webkit-scrollbar-track:horizontal {
    background: white;
}

/* Handle */
::-webkit-scrollbar-thumb:horizontal {
    background: lightgray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover:horizontal {
    background: lightgray;
}

/* Carousel */
.carousel-caption p {
    font-size: 20px;
    line-height: 1.4;
}

/* Make .svg files in the carousel display properly in older browsers */
.carousel-inner .item img[src$=".svg"] {
    width: 100%;
}

/* QR code generator */
#qrCode {
    margin: 15px;
}

/* Hide/rearrange for smaller screens */
@media screen and (max-width: 767px) {
    /* Hide captions */
    .carousel-caption {
        display: none;
    }
}

#login-form-wrapper {
    height: 85vh;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    text-align: left;
}

#login-form {
    margin: auto;
    width: 100%;
    max-width: 500px;
    padding: 10px;
}

#login-form-wrapper-mobile {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
}

#login-form-mobile {
    margin: auto;
    width: 100%;
    max-width: 450px;
    padding: 20px 20px 20px 20px;
}

.pager-wrapper {
    padding-top: 15px;
    display: inline-block;
}

.left-pager-wrapper {
    float: left;
    padding-top: 4px;
    height: 32px;
}

.right-pager-wrapper {
    padding-top: 4px;
    display: inline-block;
}

.pager {
    float: left;
}

.pager-label {
    padding-left: 5px !important;
    margin-top: 4px;
    display: inline !important;
    vertical-align: middle;
    float: left;
}

.pager-textbox {
    padding: 0px 5px 0px 5px !important;
    margin-top: -2px;
    width: 65px !important;
    text-align: center;
    float: left;
}

.pager-dropdown {
    margin-top: -2px;
    width: 80px !important;
    text-align: left;
    float: right !important;
}

.dropdown-wrapper {
    float: right;
    margin-top: 2px;
}

    .dropdown-wrapper div {
        display: inline !important;
    }

.ms-CommandBar-primaryCommand button {
    border-width: 0px !important;
}

.popup {
    min-width: 400px;
    max-width: 100%;
}

.popup-header {
    background-color: #fde354;
    padding: 15px;
}

.popup-body {
    padding: 10px;
    background-color: white;
}

.default-panel {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.mobile-popup-body {
    padding: 16px 8px 16px 8px;
}

.default-padding {
    padding: 15px;
}

.truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.picture-wrapper {
    border: 1px solid white;
    width: 100%;
    user-select: unset;
}

.product-picture {
    background: white !important;
    border-bottom: none;
}

.product-picture img {
    max-height: 100%;
    max-width: 100%;
}

.commandbar-icon {
    background: none;
    border-style: none;
    height: 22px;
}

.list-grid-tile {
    text-align: left;
    outline: none;
    position: relative;
    float: left;
    background: white;
}

.list-grid-tile-print {
    text-align: left;
    outline: none;
    position: relative;
    float: left;
    background: white;
    height: 320px;
}

.product-picture-card, .product-picture-card2, .order-product-picture-card {
    padding-left: 20px;
}

@media (max-width: 500px) {
    .product-picture-card, .product-picture-card2, .order-product-picture-card {
        padding-left: 0px;
    }
}

.mobile-product-picture-card {
    height: 300px;
    padding-left: 5px;
    padding-right: 5px;
}

.menu-title {
    font-size: larger;
    /*font-weight: 700;*/
    border-bottom: 1px solid lightgray;
    padding-bottom: 5px;
}

.menu-text {
    border-bottom: 1px solid lightgray;
    padding: 8px 0px 5px 5px;
}

.menu-title:hover {
    background-color: lightgray;
}

.submenu {
    margin-left: 30px;
}

div.form-data label {
    font-weight: 700;
    padding-right: 10px;
}

div.form-data {
    padding-bottom: 5px;
}

.icon {
    padding: 5px 5px 5px 5px !important;
    font-size: 20px;
    color: black;
}

.delete-cart {
    /*position: absolute;*/
    z-index: 20;
    padding-top: 5px;
    padding-bottom: 5px;
}

.underline-combobox {
    width: 200px;
    display: inline-block;
    padding-top: 5px;
}

    .underline-combobox div {
        border: none;
    }

    .underline-combobox input {
        border-bottom: solid 1px lightgray;
    }

div.inline {
    display: inline-block;
}

div div.underline-dropdown {
    border: none;
    border-bottom: solid 1px lightgray;
}

    div div.underline-dropdown span.ms-Dropdown-title {
        border: none;
    }

.fix-position {
    overflow: hidden;
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.disable-text {
    user-select: none; /* CSS3 (little to no support) */
    -ms-user-select: none; /* IE 10+ */
    -moz-user-select: none; /* Gecko (Firefox) */
    -webkit-user-select: none; /* Webkit (Safari, Chrome) */
}

.expand-collapse-icon {
    color: gray;
}

.add-icon {
    color: rgb(16, 110, 190);
}

.remove-icon {
    color: #ff0000;
}

.user-info {
    float: right;
    padding-right: 15px;
    height: 35px;
}

.user-info-img {
    border-radius: 50%;
    width: 30px;
}

.user-info span {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 15px;
}

.product-command {
    display: inline-flex;
    vertical-align: bottom;
}

    .product-command div {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 5px;
    }

.mobile-popup {
    overflow-y: scroll;
    overflow-x: hidden;
    width: -webkit-fill-available;
    min-width: 400px;
    max-width: 450px;
    height: 100vh;
    min-height: 700px;
    background-color: white;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 10;
    border-bottom: 1px solid lightgrey;
}

.mobile-popup-padding {
    padding: 15px 15px 15px 15px;
}

.mobile-product-full-price-wrapper {
    display: inline-block;
    margin-top: -3px;
}

.mobile-list-grid-tile {
    text-align: left;
    outline: none;
    position: relative;
    float: left;
    background: white;
    height: 300px;
}

.mobile-product-detail-wrapper {
    float: left;
    width: 100%;
}

.mobile-product-detail-row {
    float: inherit;
    width: inherit;
}

.mobile-addtocart-wrapper {
    padding-top: 15px;
    text-align: right;
}

.mobile-addtocart-button {
    width: 130px;
    /*background-color: white;*/
}

.accepted-background {
    background-color: forestgreen;
}

.firmed-background {
    background-color: #ab0000;
}

.accepted {
    color: forestgreen;
}

.firmed {
    color: #ab0000;
}

.white {
    color: white;
}

.firmed-button {
    padding-left: -10px;
    padding-right: -10px;
}

.chat-date {
    padding-bottom: 5px;
    text-align: center;
    color: gray;
}

.chat-text {
    padding: 5px 15px 5px 15px;
    border-radius: 50px;
    display: inline-block;
}

.chat-buyer {
    background-color: #F2F3F4;
}

.chat-seller {
    background-color: #AED6F1;
    float: right;
}

.price-negotiation-item {
    border-bottom: 1px solid lightgrey;
    width: 100%;
    padding-bottom: 10px;
    padding-top: 15px;
}

.number {
    text-align: right;
}

.chat-content {
    height: 60vh;
    overflow-y: auto;
    margin-top: 8px;
    margin-bottom: 8px;
}

.chat-input {
    position: fixed;
    bottom: 0px;
    padding-bottom: 15px;
    background-color: white;
}

.grid-content-admin {
    display: grid;
}

.accepted-color {
    color: forestgreen;
}

.warning-color {
    color: #ab0000;
}

.warning-color2 {
    color: #ff7f27;
}

.download-icon {
    color: forestgreen;
}

.large-icon {
    font-size: 20px;
    height: 20px;
    width: 20px;
}

.selected-text {
    background-color: forestgreen;
    color: white;
    border-radius: 20px;
    padding: 0px 5px 2px 5px;
}

.active {
    color: forestgreen;
    font-weight: 600;
    font-style: italic;
}

.inactive {
    color: #ab0000;
    font-weight: 600;
    font-style: italic;
}

.product-item-action-wrapper {
    width: 100%;
    margin-left: 0px;
    border-bottom: 1px solid lightgray;
}

.product-item-action-wrapper > div {
    display: inline-block;
}

.image-gallery-thumbnail {
    width: 60px !important;
    border: none !important;
}

div.scrollmenu {
    overflow: auto;
    white-space: nowrap;
}

.home-page-container {
    width: 100%;
    text-align: -webkit-center;
}

.home-page-body {
    max-width: 1500px;
    min-height: 200px;
}

.top-story-wrapper {
    padding-top: 18px;
}

.category-outer {
    padding-top: 15px;
}

.category-wrapper {
    height: 205px;
    padding-top: 10px;
    scroll-behavior: smooth;
}

.caterogy-item {
    display: inline-block;
    width: 160px;
    height: 160px;
}

.caterogy-item > div {
    background-color: white;
}

.caterogy-item div.body {
    height: 150px;
    background: white !important;
}

.caterogy-item .footer-text {
    text-align: center;
    padding: 15px 5px 0px 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.caterogy-item-mobile {
    display: inline-block;
    width: 160px;
    height: 160px;
}

.caterogy-item-mobile > div {
    background-color: white;
}

.caterogy-item-mobile div.body {
    height: 170px;
    background: white !important;
}

.caterogy-item-mobile .footer-text {
    padding-left: 5px;
    padding-right: 5px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
}

.brand-wrapper {
    display: inline-block;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
}

.brand-item {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
}

.facebook-button {
    background-color: #4064AD;
    color: white;
}

.price-request-new-update {
    font-weight: bold;
    font-style: italic;
    padding-top: 3px;
    color: red;
}

#main-content {
    min-height: 800px;
}

.inlineBlock {
    display: block;
}

.inlineBlock div {
    display: inline-block;
}

.product-picker-wrapper {
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    float: left;
    margin-right: 8px;
}

.product-picker-wrapper div {
    width: 100%;
    height: 100%;
}

.color-group-wrapper {
    width: 40px;
    min-width: 40px;
    height: 45px;
    min-height: 45px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.color-group-item {
    height: 40px !important;
    min-height: 40px;
    border: 1px solid lightgrey;
}

.picture-color-group-wrapper {
    width: 60px;
    min-width: 60px;
    height: 65px;
    min-height: 65px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.picture-color-group-item {
    height: 60px !important;
    min-height: 60px;
    border: 1px solid lightgrey;
}

.color-selected {
    background-color: #fde354;
    height: 3px !important;
    vertical-align: top;
}

.textbox-group-size-wrapper {
    height: 40px;
    min-width: 30px;
    max-width: 100px;
    font-size: small;
    padding-left: 10px;
    padding-right: 10px;
}

.textbox-group-others-wrapper {
    height: 30px;
    min-width: 40px;
    padding: 5px 8px 5px 8px;
}

.textbox-group-wrapper {
    border: 1px solid lightgrey;
    text-align: center;
    vertical-align: middle;
    display: grid;
    text-size-adjust: auto;
}

.textbox-group-wrapper > div {
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: -webkit-baseline-middle;
}

.textbox-group-wrapper span {
    vertical-align: -webkit-baseline-middle;
}

.size-selected, .others-selected {
    background-color: black;
    color: white;
}

.border-top {
    border-top: 1px solid lightgrey;
}

.border-bottom {
    border-bottom: 1px solid lightgrey;
}

.pending-qty {
    color: lightgreen;
}

.available-qty {
    color: darkgreen;
}

.request-qty {
    color: orange;
}

.product-detail-buttons {
    text-align: right;
    width: 100%;
    display: inline-block;
    padding-top: 15px;
    padding-bottom: 30px;
}

.order-status {
    font-style: italic;
    font-weight: 700;
    color: forestgreen;
}

.approved-status {
    font-style: italic;
    font-weight: 700;
    color: forestgreen;
}

.rejected-status {
    font-style: italic;
    font-weight: 700;
    color: firebrick;
}

.line1 {
    border: 1px solid gray;
    width: 112px;
    height: 47px;
    border-bottom: 1px solid red;
    -webkit-transform: translateY(-20px) translateX(5px) rotate(27deg);
    position: absolute;
}

.line2 {
    border: 1px solid gray;
    width: 112px;
    height: 47px;
    border-bottom: 1px solid green;
    -webkit-transform: translateY(20px) translateX(5px) rotate(-26deg);
    position: absolute;
    top: -33px;
    left: -13px;
}

.diagonal {
    background: linear-gradient(to top right, #fff calc(50% - 1px), red, #fff calc(50% + 1px))
}

.company-name {
    font-weight: 900;
    font-size: x-large;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 24px;
}

.company-icon {
    margin-bottom: -15px;
    width: 42px;
    height: 40px;
}

.scroll-icon {
    width: 3%;
    padding-top: 90px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
    div.stickytop {
        position: sticky;
        top: 0;
        bottom: 0;
        max-height: 100vh;
        overflow-y: auto;
    }
}

.contact-description {
    padding-left: 5px;
    font-style: italic;
    font-size: small;
}

#footer-container {
    margin: 0px -8px -8px -8px;
    background-color: black;
}

.footer-wrapper {
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px;
}

.footer-wrapper-mobile {
    background-color: black;
    text-align: left;
    padding: 16px 8px 16px 8px;
    margin-left: -8px;
    margin-right: -8px;
}

.top-info {
    background-color: rgb(236, 236, 236);
    color: black;
    height: 40px;
    text-align: center;
}

.download-app {
    background-color: rgb(236, 236, 236);
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 600;
}

.banner {
    background-color: black;
    color: white;
    height: 45px;
    padding: 0px 6px 0px 6px;
}

    .banner a {
        color: white;
    }

.cart-wrapper {
    display: inline-block;
    width: 28px;
    height: 28px;
    margin-top: 2px;
}

.cart-count {
    font-size: x-small;
    left: -4px;
    top: -25px;
    display: inline-block;
    position: relative;
    width: 20px;
    text-align: center;
}

.page-menu {
    border: none;
    background-color: black;
    display: inline-block;
}

.page-menu:hover {
    background-color: black;
}

.shopping-by-menu {
    min-width: 50px;
    width: fit-content;
}

.shopping-by-menu:hover {
    background-color: white !important;
}

.view-cart {
    width: 1000px;
    max-width: 100%;
    text-align: left;
}

.confirmation-text {
    padding-bottom: 10px;
}

.confirmation-note {
    padding-top: 10px;
    padding-bottom: 10px;
}

.confirmation-bold {
    font-weight: bold;
}

.quick-link {
    display: inline-block;
    padding-right: 20px;
    font-weight: 600;
    font-size: larger;
}

.quick-link-item {
    padding-bottom: 5px;
}

.quick-link:hover, .quick-link-item:hover {
    text-decoration: underline;
}

.quick-link-content {
    position: absolute;
    z-index: 100;
    background: white;
    min-width: 400px;
    text-align: left;
    padding: 20px 20px 20px 20px;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
}

.grid-item {
    padding: 20px;
}

.quick-link-mobile {
    position: absolute;
    z-index: 90;
    background: white;
    width: 96%;
    text-align: left;
    padding: 20px 20px 20px 20px;
}

.quick-link-content-mobile {
    position: absolute;
    z-index: 100;
    background: white;
    width: 96%;
    text-align: left;
    padding: 20px 20px 20px 0px;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto;
}

.quick-link-group-name {
    font-weight: bold;
    font-size: large;
    padding-top: 15px;
    padding-bottom: 5px;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}

.rs-tracker {
    padding: 22px 0 0
}

@media only screen and (max-width: 1023px) and (max-device-width:736px) {
    .rs-tracker {
        overflow-x: scroll;
        padding: 18px 0 0
    }
}

.rs-tracker .rs-tracker-bar {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #d2d2d7
}

.rs-tracker .rs-tracker-fill {
    height: 5px;
    border-radius: inherit;
    overflow: hidden;
    transition: width 1s ease-in-out;
    background-color: #fde354
}

    .rs-tracker .rs-tracker-fill.rs-tracker-fill-disabled {
        background-color: #6e6e73
    }

.rs-tracker .rs-tracker-status {
    font-size: 12px;
    line-height: 1.33337;
    font-weight: 400;
    letter-spacing: -.01em;
    /*font-family: SF Pro Text,SF Pro Icons,AOS Icons,Helvetica Neue,Helvetica,Arial,sans-serif;*/
    color: #6e6e73;
    margin: 12px 0;
    width: inherit;
    position: relative
}

.rs-tracker .rs-tracker-status li {
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    vertical-align: top
}

.rs-tracker .rs-tracker-status li:first-child {
    text-align: left
}

.rs-tracker .rs-tracker-status li:last-child {
    text-align: right
}

.rs-tracker .rs-tracker-status .rs-status {
    white-space: normal;
    word-wrap: break-word
}

.rs-tracker .rs-tracker-status .rs-status .rs-status-substatus {
    display: block
}

.rs-tracker .rs-tracker-status .rs-status-current {
    font-weight: 600;
    color: #1d1d1f
}

.rs-tracker .rs-tracker-status .rs-status-a11y {
    position: absolute;
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(0 0 99.9% 99.9%);
    clip-path: inset(0 0 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0
}

.rs-appcontainer {
    min-height: 400px
}

.rs-appcontainer .rs-pageerror {
    margin: 50px auto
}

.rs-appcontainer .rs-pageerror-wrapper .rs-chatnow {
    border-bottom: 1px solid #d2d2d7
}

.rs-space-after:after, .rs-space-before:before {
    content: " "
}

@media only screen and (max-width: 1023px) and (max-device-width:736px) {
    body {
        min-width: 320px
    }
}

.as-l-container {
    margin-left: auto;
    margin-right: auto;
    width: 980px
}

@media only screen and (min-width: 1441px) {
    .as-l-container {
        margin-left: auto;
        margin-right: auto;
        width: 980px
    }
}

@media only screen and (max-width: 1023px) and (max-device-width:736px) {
    .as-l-container {
        margin-left: auto;
        margin-right: auto;
        width: 94.14966%;
        width: 87.5%
    }
}

@media only screen and (max-width: 1023px) and (max-device-width:736px) {
    .as-l-container-mobileoverflow {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        white-space: nowrap;
        width: auto
    }

    .as-l-container-mobileoverflow::-webkit-scrollbar {
        display: none
    }
}

@media only screen and (max-width: 1023px) and (max-device-width:736px) {
    .as-l-container-mobileoverflow-content {
        box-sizing: border-box;
        display: inline-block;
        min-width: 100%
    }
}

@media only screen and (max-width: 1023px) and (max-device-width:736px) and (max-width:1023px) and (max-device-width:736px) {
    .as-l-container-mobileoverflow-content {
        width: auto;
    }
}

.as-l-container-fluid {
    box-sizing: border-box;
    max-width: 1439px;
    min-width: 320px;
    width: 100%
}

.default-highlight-text {
    margin: 0px 10px 5px 0px;
    padding: 5px 10px 5px 10px;
    display: inline-block;
    border-radius: 10px;
}

.circle-text {
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid forestgreen;
    border-radius: 50px;
}

.effective-text {
    background-color: forestgreen;
    color: white;
}

.expired-text {
    background-color: #ab0000;
    color: white;
}

.future-text {
    background-color: lightskyblue;
    color: black;
}

.filter-text {
    margin: 0px 10px 5px 0px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #edf2f9;
}

.filter-text2 {
    padding: 5px 10px 5px 10px;
    background-color: #edf2f9;
}

.highlight-text {
    margin: 0px 10px 10px 0px;
    padding: 0px 10px 3px 10px;
    background-color: #edf2f9;
    display: inline-block;
}

.web-link-item {
    border-bottom: 1px solid lightgray;
    padding-bottom: 8px;
    margin-bottom: 8px;
}

.policy {
    font-size: medium;
    text-align: left;
}

.policy p, .policy h3, .policy h4, .policy ul {
    padding-bottom: 20px;
}

.policy p, ul {
    line-height: 1.4;
}

.policy-popup {
    position: fixed;
    bottom: 0px;
    text-align: center;
    background-color: white;
}

.policy-popup-content-wrapper {
    background-color: black;
    color: white;
}

.policy-popup-content {
    text-align: left;
    padding-left: 8px;
    padding-right: 8px;
}

.qty-label div {
    display: inline-block;
}

.qty-bar div {
    height: 5px;
    display: inline-block;
}

.qty-bar div:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.qty-bar div:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.button-wrapper {
    float: right;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
    max-width: 450px;
}

.button-wrapper > div {
    padding-top: 10px;
}

.button-wrapper > div > button {
    width: 100%;
}

.product-promotion-info {
    display: inline;
    padding-left: 20px;
}

.top-product-row div:first-child {
    padding-right: 4px;
}

.top-product-row div:last-child {
    padding-left: 4px;
}

h3.homepage {
    margin-top: 8px;
    margin-bottom: 8px;
}

hr.grid-item {
    background-color: lightgray;
    border: none;
    height: 1px;
    padding: unset;
}

.no-padding-left {
    padding-left: 0px;
}

.no-padding-right {
    padding-right: 0px;
    text-align: right;
}

.qty-bar {
    width: 100%;
    height: 14px;
    text-align: right;
}

.qty-bar-text {
    height: 12px;
}

.qty-bar-text div {
    text-align: center;
    font-size: x-small;
}

.qty-bar div:first-child {
    border-top-left-radius: 10px;
}

.qty-bar div:last-child {
    border-top-right-radius: 10px;
}

.grid-action-menu:hover {
    background: none;
}

.brand-letter {
    width: 30px;
    display: inline-block;
    font-size: large;
    font-weight: 700;
}

.brand-item-row {
    padding: 5px 5px 5px 5px;
    font-size: medium;
}

.brand-dropdown {
    min-height: 32px;
    border: 1px solid black;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 4px
}

.brand-dropdown-icon {
    float: right;
    padding-right: 10px;
    padding-top: 6px;
    font-size: smaller;
}

.brand-dropdown-text {
    padding-left: 12px;
    padding-top: 4px;
    display: inline-block;
}

.small {
    font-size: smaller;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.css-185::after {
    border-radius: 10px !important;
}

.ms-TextField-fieldGroup, .ms-Dropdown, .ms-Dropdown-title, .ms-Button, .ms-ComboBox, .brand-dropdown,
.searchbox, #login-form, .picture-wrapper:hover, .product-picker-wrapper div, .color-group-wrapper,
.picture-color-group-item, .textbox-group-size-wrapper, .textbox-group-others-wrapper, .highlight-text,
.filter-text, .filter-text2, .image-radius, .image-radius img, .top-story-item, .default-radius, ms-TextField {
    border-radius: 10px;
}

.ms-Checkbox-checkbox {
    border-radius: 4px;
}

div.underline-dropdown {
    border-radius: 0px !important;
}

@media only screen and (max-width: 1400px) {
    #quicklink {
        display: none;
    }

    #quicklinkCompressed {
        display: normal !important;
    }
}

@media only screen and (min-width: 1401px) {
    #quicklink {
        display: normal;
    }

    #quicklinkCompressed {
        display: none;
    }
}

.cart-box {
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: -2px 2px black;
    position: sticky;
    top: 0;
    bottom: 0;
    max-height: 80vh;
    overflow-y: auto;
}

.cart-box-cancel {
    text-align: right;
    padding-top: 10px;
    padding-right: 4px;
    height: 25px;
}

.unavailable-text {
    font-size: smaller;
    background-color: #ab0000;
    color: white;
    float: right;
    border-radius: 5px;
    padding: 0px 5px 2px 5px;
    margin-top: 2px;
    margin-left: 2px;
}

.flashsale-text {
    font-size: smaller;
    background-color: #EDF0FF;
    color: black;
    float: right;
    border-radius: 5px;
    padding: 0px 5px 2px 5px;
    margin-top: 2px;
    margin-left: 2px;
}

.feature-text {
    font-size: smaller;
    background-color: #AED6F1;
    color: black;
    float: right;
    border-radius: 5px;
    padding: 0px 5px 2px 5px;
    margin-top: 2px;
    margin-left: 2px;
}

.onetime-text {
    font-size: smaller;
    background-color: #D2B4DE;
    color: black;
    float: right;
    border-radius: 5px;
    padding: 0px 5px 2px 5px;
    margin-top: 2px;
    margin-left: 2px;
}

.instock-text {
    font-size: smaller;
    float: right;
    background-color: forestgreen;
    color: white;
    border-radius: 5px;
    padding: 0px 5px 2px 5px;
    margin-top: 2px;
    margin-left: 2px;
}

.instock-text2 {
    font-size: xx-small;
    background-color: forestgreen;
    color: white;
    border-radius: 5px;
    padding: 0px 3px 2px 3px;
    height: 11px;
}

.context-menu-text {
    border-radius: 10px;
    background-color: black;
    height: 35px;
    padding: 0px 5px 0px 5px;
}

    .context-menu-text:hover {
        background-color: black;
    }

.menu-icon {
    border-radius: 10px;
    background-color: black;
    width: 25px;
    height: 21px;
    text-align: center;
    padding: 7px 5px 5px 5px !important;
    font-size: 20px;
}

    .mini-picture-wrapper:hover, a:hover, .icon:hover, .default-icon:hover, .download-icon, .large-icon,
    .color-group-wrapper:hover, .picture-color-group-wrapper:hover, .textbox-group-wrapper:hover,
    .clickable:hover, .company-name span:hover, .caterogy-item:hover, .top-story-item:hover, .brand-item:hover,
    .scroll-icon:hover, .highlight-text, .page-menu:hover, .shopping-by-menu:hover, .quick-link:hover,
    .quick-link-item:hover, .product-order-queue, .grid-action-menu:hover, .brand-item-row:hover, .brand-dropdown:hover, .pointer:hover,
    .menu-icon:hover {
        cursor: pointer;
    }

.default-button {
    background-color: white;
    border: 1px solid black;
}

.mini-product-picture img {
    max-width: 100%;
    max-height: 100%;
}

.mini-picture {
    display: block;
    max-width: 50px;
    max-height: 50px;
    width: auto;
    height: auto;
}

.contextual-menu-item {
    background-color: black;
}

.status-block span {
    padding: 2px 8px 5px 8px;
    margin-left: 5px;
    margin-bottom: 2px;
    margin-top: 2px;
    border-radius: 5px;
    font-weight: 500;
    color: white;
}

.status-block span:first-child {
    margin-left: 0px !important;
}

.box {
    border: 1px solid lightgray;
    box-shadow: 2px 2px 4px lightgray;
    border-radius: 10px;
}

.default-shadow {
    box-shadow: 2px 2px 4px lightgray;
    border-radius: 10px;
}

.detail-form {
    margin-left: 0px;
    margin-right: 0px;
}

.selected-box {
    border: 2px solid forestgreen !important;
}

.center {
    text-align: center;
}

.option-picker {
    font-size: medium;
    height: 30px;
    min-height: 30px;
    padding: 12px 16px 0px 16px;
    border: none;
    background-color: aliceblue;
    font-weight: bold;
    white-space: nowrap;
}

.queue-item-menu {
    border-radius: 20px;
    background-color: lightblue;
    color: white;
    cursor: pointer;
    text-align: center;
    font-size: medium;
    height: 150px;
    width: 15px;
    float: right;
}

.queue-item-menu i {
    font-size: smaller;
}

.queue-item-menu i:last-child {
    padding-top: 112px;
}

.product-order-queue {
    border: 1px solid forestgreen;
    /*text-align: center;*/
    margin: auto;
    display: table-cell;
}

.status-block-stack span {
    border-radius: 5px;
    font-weight: 500;
    color: white;
    width: 100%;
    text-align: center;
    margin-bottom: 3px;
    height: 22px;
}

.move-up {
    transform: rotate(-90deg);
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: right;
}

.move-down {
    transform: rotate(90deg);
    width: 25px;
    height: 25px;
}

.move-icon {
    color: forestgreen;
    background-color: white;
    font-size: large;
}

.summary-border {
    margin: 20px 0px 20px 0px;
    padding: 15px 15px 15px 15px;
    border: 1px solid lightgray;
    border-radius: 20px;
}

h2 {
    padding-left: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
}

div.title {
    font-weight: bold;
    font-size: large;
    padding-bottom: 12px;
}

.inline-choice-group .ms-ChoiceField {
    margin-top: 0px;
    padding-right: 20px;
}

.go-top {
    display: inline-block;
    vertical-align: middle;
    float: right;
    font-size: 20px;
    padding-top: 18px;
}

.price-setting > div {
    padding-bottom: 5px;
}

.count {
    margin-left: 5px;
    padding: 2px 6px 2px 6px;
    border-radius: 10px;
    background-color: #fde354;
    color: black;
    font-size: smaller;
}

.center-cropped {
    object-fit: cover;
    object-position: center;
}

img.center-cropped:last-child {
    padding-bottom: 0px !important;
    padding-right: 0px !important;
}

.highlight-label {
    color: gray;
}

.bold {
    font-weight: bold;
}

/*padding all*/
.p-0 {
    padding: 0px 0px 0px 0px !important;
}

.p-5 {
    padding: 5px 5px 5px 5px !important;
}

.p-10 {
    padding: 10px 10px 10px 10px !important;
}

.p-15 {
    padding: 15px 15px 15px 15px !important;
}

.p-20 {
    padding: 20px 20px 20px 20px !important;
}

/*padding vertical*/
.pv-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.pv-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.pv-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.pv-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.pv-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

/*padding horizontal*/
.ph-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.ph-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.ph-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.ph-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.ph-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.ph-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

/*padding top*/
.pt-0 {
    padding-top: 0px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-25 {
    padding-top: 25px !important;
}

/*padding bottom*/
.pb-0 {
    padding-bottom: 0px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

/*padding left*/
.pl-0 {
    padding-left: 0px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pl-8 {
    padding-left: 8px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

/*padding right*/
.pr-0 {
    padding-right: 0px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-8 {
    padding-right: 8px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

/*margin all*/
.m-10 {
    margin: 10px 10px 10px 10px !important;
}

.m-20 {
    margin: 20px 20px 20px 20px !important;
}

/*margin top*/
.mt-0 {
    margin-top: 0px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

/*margin bottom*/
.mb-0 {
    margin-bottom: 0px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

/*margin left*/
.ml-0 {
    margin-left: 0px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

/*margin right*/
.mr-0 {
    margin-right: 0px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.font-bold {
    font-weight: bold;
}

.font-bolder {
    font-weight: bolder;
}

.border-top {
    border-top: 1px solid lightgray;
}

.border-bottom {
    border-bottom: 1px solid lightgray;
}

.border-left {
    border-left: 1px solid lightgray;
}

.lightgray {
    color: lightgray;
}

.gray {
    color: gray;
}

.blue-background {
    background-color: aliceblue;
}

div.tab-buttons span {
    cursor: pointer;
    padding: 10px;
    margin-right: 5px;
    border: 1px solid lightgray;
    min-width: 70px;
    display: inline-block;
    text-align: center;
}

.dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
}

span.order-item-label {
    width: 80px;
    color: gray;
    display: inline-block;
}

a {
    color: black;
}

.ms-DetailsHeader {
    padding-top: 0px;
}

.ms-Callout, .ms-Panel-contentInner {
    background-color: white;
}

.disable-action-button .ms-Button--action:hover i, .disable-action-button .ms-Button--action:hover span {
    color: black;
}

.fl-grid {
    margin-left: 0px;
    margin-right: 0px;
}

.ms-Grid-col {
    float: left;
}

.ms-Grid-col-right {
    padding-left: 10px;
}

@media (max-width: 640px) {
    .ms-Grid-col-right {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .popup-body {
        padding-bottom: 50px;
    }
}

.ms-Button-label {
    color: black;
}

.font-smaller {
    font-size: smaller;
}